<template>
  <v-container>
    <p>Home</p>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      layoutData: {
        headline: 'Vajper Core'
      }
    }
  },
  created() {
    this.$emit('layoutData', this.layoutData)
  }
}
</script>

<style>

</style>